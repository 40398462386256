import React, { useState, useEffect } from "react";
import "./About.scss";
import { motion } from "framer-motion";

import { AppWrap } from "../../wrapper";
import { MotionWrap } from "../../wrapper";

// ! sanity data
import { client, urlFor } from "../../client";
/*
const about = [
  {
    title: "Web Development",
    description: "I am a Good Web Developer",
    imgUrl: images.about01,
  },
  {
    title: "Frontend Development",
    description: "I am a Good Frontend Developer",
    imgUrl: images.about02,
  },
  {
    title: "Backend Development",
    description: "I am a Good Backend Developer",
    imgUrl: images.about03,
  },
  {
    title: "MERN Stack",
    description: "I am a Good at Full Stack MERN",
    imgUrl: images.about04,
  },
];
*/
const About = () => {
  const [abouts, setAbouts] = useState([]);
  useEffect(() => {
    const query = '*[_type == "abouts"]';
    client
      .fetch(query)
      .then((data) => setAbouts(data))
      .catch(console.error);
  }, []);
  return (
    <>
      <h2 className="head-text">
        i Know that <span>Good Application</span>
        <br /> means<span> Good Bussiness</span>
      </h2>

      <div className="app__profiles">
        {abouts.map((about, index) => {
          return (
            <motion.div
              whileInView={{ opacity: 1 }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5, type: "tween" }}
              className="app__profiles-item"
              key={about.title + index}
            >
              <img src={urlFor(about.imgUrl)} alt={about.title} />
              <h2 className="bold-text" style={{ marginTop: 20 }}>
                {about.title}
              </h2>
              <p className="p-text" style={{ marginTop: 10 }}>
                {about.description}
              </p>
            </motion.div>
          );
        })}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
